/**
 *
 * SOCIAL
 *
 */

.social {
	display: flex; align-items: center; justify-content: center; width: 100%; padding: gap('small') gap('xsmall');
	border-top: 1px solid color('neutrals-1'); border-bottom: 1px solid color('neutrals-1');
	background-color: #fff;

	@include media('medium') {
		padding: gap('small');
	}
}

.social__inner {
	display: flex; flex: 0 1 section('xsmall');
}

.social__visual {
	display: block; align-self: flex-start; flex: 0 0 40px;
	color: color('facebook'); text-decoration: none;

	@include media(400px) {
		flex-basis: 60px;
	}

	@include media('small') {
		flex-basis: 80px;
	}

	@include media('medium') {
		flex-basis: 100px;
	}
}

.social__content {
	flex: 0 1 auto; padding-left: gap('xsmall');

	@include media('small') {
		padding-left: gap('small');
	}
}

.social__desc {
	margin-top: gap('xxsmall');
}

.social__cta {
	transition: color 200ms;
	display: block; margin-top: gap('xxsmall');
	color: color('facebook'); font-weight: bold; text-decoration: none;

	&:hover {
		color: color('hover');
	}
}