/**
 *
 * HAMBURGER
 *
 */

.hamburger {
	display: flex; align-items: center; justify-content: center; width: 30px; height: 40px;
	cursor: pointer;
}

.hamburger__inner	{
	display: flex; flex-direction: column; align-items: center; justify-content: space-between; width: 100%; height: 19px;

	.offcanvas-is-visible & {
		height: 24px;
	}
}

.hamburger__inner:before,
.hamburger__inner:after {
	transition: transform 300ms;
	display: block; width: 100%; height: 3px;
	content: '';
	border-top-width: 2px; border-bottom-width: 1px; border-style: solid;
}

.hamburger__inner:before {
	.offcanvas-is-visible & {
		transform: translateY(10px) rotate(-45deg);
	}
}

.hamburger__bar {
	transition: transform 300ms, opacity 150ms;
	display: block; width: 100%; height: 0;
	border-top-width: 2px; border-bottom-width: 1px; border-style: solid;

	.offcanvas-is-visible & {
		opacity: 0;
	}
}

.hamburger__inner:after {
	.offcanvas-is-visible & {
		transform: translateY(-10px) rotate(45deg);
	}
}