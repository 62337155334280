/**
 *
 * SYMBOLS
 *
 */

.symbol {
	display: block; width: 100%; height: 100%; padding: 0; margin: 0;
}

.symbol {
	g,
	group {
		fill: currentColor;
	}
}