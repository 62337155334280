/**
 *
 * COLORS: VARS
 *
 */

$colors: (
	'light': #fff,
	'dark': #000,
	'brand': #679C11,
	'brand-light': #A7C517,
	'action': #DE6224,
	'hover': #E58926,
	'disabled': #d9d9d9,
	'positive': green,
	'negative': red,
	'neutrals-0': #f3f3f3,
	'neutrals-1': #ddd,
	'neutrals-2': #bbb,
	'neutrals-3': #8e8e8e,
	'neutrals-4': #777,
	'neutrals-5': #555,
	'neutrals-6': #333,
	'neutrals-7': #111,
	'neutrals-8': #000,
	'facebook': #3B5998
);