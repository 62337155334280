/**
 *
 * SPECIALIST INFO
 *
 */

.specialistInfo {}

.specialistInfo__content {
	margin-top: gap('xsmall');
}

.specialistInfo__heading {}

.specialistInfo__subtitle {
	margin: gap('xxsmall') 0 0;
	font-weight: 300;
}

.specialistInfo__contact {
	margin-top: gap('small');
}

.specialistInfo__link {
	display: flex; align-items: center; margin-top: gap('xsmall');

	&:first-child {
		margin-top: 0;
	}
}

.specialistInfo__icon {
	display: block; flex: 0 0 20px; height: 20px; margin-right: gap('xsmall');
}