/**
 *
 * INTRO
 *
 */

.intro {
	display: block; width: 100%;

	@include media('medium') {
		padding: gap('small');
	}

	@include media('medium') {
		padding: gap('medium') gap('small');
	}

	@include media('xlarge') {
		padding: gap('large') gap('small');
	}
}

.intro__inner {
	@include media('medium') {
		display: flex; width: 100%; max-width: section('xlarge'); margin: 0 auto;
	}
}

.intro__visual {
	display: block; width: 100%;

	@include media('medium') {
		flex: 0 0 40%;
	}

	@include media('large') {
		flex: 0 0 30%;
	}
}


.intro__image {
	position: relative;
	display: block; width: 100%; height: 0; padding-top: 50%;
	background: #fff url('') no-repeat center / cover;

	@include media('medium') {
		border-radius: 7px;
		padding-top: (6 / 4) * 100%;
		overflow: hidden;
	}
}

.intro__image:after {
	position: absolute; top: 0; left: 0;
	display: block; width: 100%; height: 100%;
	content: '';
	background-color: rgba(#000, .07);
}

.intro__content {
	padding: gap('small') gap('xsmall');

	@include media('medium') {
		flex: 0 0 60%; padding: 0 0 0 gap('small');
	}

	@include media('large') {
		flex: 0 0 70%; padding: 0 0 0 gap('medium');
	}
}

.intro__desc {
	margin-top: gap('xsmall');
}

.intro__cta {
	margin-top: gap('small');
}