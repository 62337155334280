/**
 *
 * LOGO
 *
 */

.logo {
	display: block;
}

.logo__graphic {
	display: block; width: 100%; height: 100%;
}