/**
 *
 * HERO
 *
 */

.hero {
	position: relative; z-index: 1;
	display: block; width: 100%; max-height: 700px; overflow: hidden;
}

.hero__visual {
	display: block; width: 100%; height: 0; padding-top: (9 / 16) * 100%;
	background: color('brand') url('') no-repeat center / cover;
}

.hero__inner {
	display: flex; align-items: flex-end; width: 100%; padding: gap('small') gap('xsmall');
	color: #fff;
	background-color: color('brand');

	@include media('medium') {
		position: absolute; top: 0; left: 0; z-index: 1;
		height: 100%; padding: gap('small');
		color: inherit;
		background-color: rgba(#000, .2);
	}

	@include media('large') {
		align-items: center;
	}
}

.hero__title {
	text-transform: uppercase;
	font-weight: 500;
	font-family: font('main');
}

.hero__content {
	display: block; flex: 1 1 auto;

	@include media('medium') {
		color: #fff; text-shadow: 0 2px 10px rgba(#000, .7);
	}

	@include media('large') {
		width: 100%; max-width: section('medium'); margin: 0 auto;
		text-align: center;
	}
}

.hero__desc {
	margin-top: gap('xsmall');
	font-size: fontSize('xlarge');
	font-weight: 700;

	@include media('large') {
		font-size: fontSize('xxlarge');
	}
}

.hero__cta {
	margin-top: gap('small');
}