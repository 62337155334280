/**
 *
 * SPECIALIST DETAIL
 *
 */

.specialistDetail {
	display: block; padding: gap('xxsmall');

	@include media('medium') {
		padding: gap('xsmall');
	}

	@include media('large') {
		padding: gap('small');
	}
}

.specialistDetail__inner {
	@include media('large') {
		max-width: section('xxlarge'); margin: 0 auto;
	}
}

.specialistDetail__content {
	@include media('large') {
		display: flex; justify-content: space-between; max-width: section('xxlarge'); margin: 0 auto;
	}
}

.specialistDetail__main {
	display: block; padding: gap('xsmall') gap('xsmall') gap('small');
	border: 1px solid color('neutrals-1'); border-radius: 5px;
	background-color: #fff;

	@include media('large') {
		$theDiff: 330px + gap('xsmall');
		flex: 0 1 auto; width: calc(100% - #{$theDiff}); padding: gap('small') gap('small') gap('medium');
	}
}

.specialistDetail__subtitle {
	font-weight: 300;
}

.specialistDetail__intro {
	margin-top: gap('xsmall');
}

.specialistDetail__mainContent {
	margin-top: gap('small');
}

.specialistDetail__aside {
	display: block; align-self: flex-start; width: 100%; margin-top: gap('xsmall');

	@include media('large') {
		flex: 0 0 330px; max-width: 330px; margin-top: 0;
	}
}