/**
 *
 * CONTACT CONTENT
 *
 */

.contactContent {
	display: block; padding: gap('small') gap('xsmall') gap('medium');
	background-color: #fff;

	@include media('medium') {
		padding: gap('medium') gap('small') gap('large');
	}
}

.contactContent__inner {
	display: block; width: 100%; max-width: section('xlarge'); margin: 0 auto;
	background-color: #fff;
}