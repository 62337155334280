/**
 *
 * COLORS: GENERAL
 *
 */

#{$theBody} {
	color: color('neutrals-7');
	background-color: #fff;
}

#{$theBody} {
  color: color('neutrals-7');
  background-color: color('neutrals-0');
}

::selection {
	color: #fff;
	background: color('action');
}

::-moz-selection {
	color: #fff;
	background: color('action');
}