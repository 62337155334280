/**
 *
 * PAGE TITLE
 *
 */

.pageTitle {
 	display: block; width: 100%; padding: gap('small') gap('xsmall') 0;

 	@include media('large') {
 		padding-top: gap('large');
 	}
}

.pageTitle__inner {
 	display: block; width: 100%; max-width: section('small'); margin: 0 auto;
 	text-align: center;

 	@include media('xlarge') {
		max-width: section('medium');
	}
}

.pageTitle__desc {
	@include media('medium') {
		font-size: fontSize('large');
	}
}