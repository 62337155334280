/**
 *
 * SPECIALIST TEASER
 *
 */

.specialistTeaser {
	transition: transform 300ms, box-shadow 300ms, border-color 300ms;
	position: relative;
	display: block; flex: 1 1 auto;/*  max-width: 520px; */ padding: gap('xsmall') gap('xsmall') gap('small'); overflow: hidden;
	color: inherit; text-decoration: none;
	border: 1px solid color('neutrals-1'); border-radius: 5px;
	background-color: #fff;

	&:hover {
		transform: translateY(-3px);
		border-color: color('hover');
		box-shadow: 0 0 50px rgba(#000, .15);
	}

	&.specialistTeaser--horizontal,
	.specialistTeaser--horizontal & {
		padding: gap('xsmall');
	}

	.specialistDetail__aside &:hover {
		transform: none;
		border-color: color('neutrals-1');
		box-shadow: none;
	}
}

.specialistTeaser__inner {
	.specialistTeaser--horizontal & {
		@include media('small') {
			display: flex; align-items: center;
		}
	}
}

.specialistTeaser__visual {
	display: block; flex: 0 0 200px; width: 200px; margin: 0 auto;

	.specialistDetail & {
		flex: 0 1 270px; width: 270px;
	}
}

.specialistTeaser__content {
	flex: 0 1 auto; margin-top: gap('xsmall');
	text-align: center;

	.specialistTeaser--horizontal & {
		@include media('small') {
			margin-top: 0; margin-left: gap('small');
			text-align: left;
		}
	}
}

.specialistTeaser__header {
	display: flex; flex-wrap: wrap; justify-content: center; width: 100%; height: 80px;

	.specialistDetail & {
		justify-content: flex-start;
		text-align: left;
	}

	.specialistTeaser--horizontal & {
		@include media('small') {
			justify-content: flex-start;
		}
	}
}

.specialistTeaser__title {
	flex: 0 0 100%; transition: color 200ms;
}

.specialistTeaser__subtitle {
	flex: 0 0 100%; margin: gap('xxsmall') 0 0;
	font-weight: 300;

	.specialistDetail & {
		font-size: fontSize('large');
	}
}

.specialistTeaser__permalink {
	position: absolute; top: 0; left: 0; z-index: 1;
	display: block; width: 100%; height: 100%;

	.specialistDetail & {
		display: none;
	}
}

.specialistTeaser__contact {
	position: relative; z-index: 3;
	margin-top: gap('xsmall');
	text-align: left;

	.specialistDetail & {
		margin-top: gap('xsmall');
	}
}

.specialistTeaser__link {
	display: flex; align-items: center; margin-top: gap('xxsmall');
	font-family: font('special'); font-weight: 300;

	.specialistDetail & {
		font-size: fontSize('xlarge');
	}

	&:first-child {
		margin-top: 0;
	}
}

.specialistTeaser__icon {
	display: block; flex: 0 0 16px; height: 16px; margin-right: gap('xxsmall');
}