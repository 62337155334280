/**
 *
 * WIDGET
 *
 */

.widget {

}

.widget__title {
	margin-bottom: gap('small');
	text-align: center;
}