/**
 *
 * RELATED TREATMENTS
 *
 */

.relatedTreatments {
	display: block; padding: gap('small') gap('xsmall'); overflow: hidden;

	@include media('large') {
		padding: gap('large') gap('small');
	}

	@include media('xlarge') {
		padding: gap('xlarge') gap('small');
	}
}

.relatedTreatments__inner {
	display: block; width: 100%; max-width: section('xxlarge'); margin: 0 auto;
}

.relatedTreatments__title {
	margin-bottom: gap('xsmall');
	text-align: center;
}

.relatedTreatments__content {
	margin-left: -(gap('xxxsmall')); margin-right: -(gap('xxxsmall'));

	@include media('large') {
		display: flex; flex-wrap: wrap; margin-left: -(gap('xxsmall')); margin-right: -(gap('xxsmall'));
	}
}

.relatedTreatments__item {
	display: flex; padding: gap('xxxsmall');

	@include media('large') {
		flex: 0 0 50%; padding: gap('xxsmall');
	}

	&:first-child {
		margin-top: 0;
	}
}