/**
 *
 * TESTIMONIAL
 *
 */

.testimonial {
	display: block; padding: gap('small') gap('xsmall') gap('medium');
	border-top: 1px solid color('neutrals-1'); border-bottom: 1px solid color('neutrals-1');
	background-color: #fff;

	@include media('medium') {
		padding: gap('small') gap('small') gap('medium');
	}
}

.testimonial__inner {
	display: block; width: 100%; max-width: 750px; margin: 0 auto;
}

.testimonial__content {
	display: flex; align-items: flex-start;
}

.testimonial__icon {
	display: block; flex: 0 0 50px; margin-right: gap('xsmall');
	color: color('brand');
}

.testimonial__desc {
	margin-top: gap('xsmall');
}

.testimonial__quoter {
	display: flex; justify-content: flex-end;
}

.testimonial__name:before {
	display: block; width: 20px; height: 0; margin-bottom: gap('xxxsmall');
	content: '';
	border: 1px solid color('brand');
}