/**
 *
 * SPECIALIST
 *
 */

.specialist {
	transition: transform 300ms, box-shadow 300ms;
	display: block; max-width: 300px; padding: gap('xsmall') gap('xsmall') gap('small');
	color: inherit; text-decoration: none;
	background-color: #fff;

	&:hover {
		transform: translateY(-3px);
		box-shadow: 0 0 50px rgba(#000, .15);
	}

	&.specialist--horizontal {
		@include media('small') {
			max-width: 100%;
		}
	}
}

.specialist__inner {
	.specialist--horizontal & {
		@include media('small') {
			display: flex; align-items: center;
		}
	}
}

.specialist__visual {
	display: block; flex: 0 1 200px; width: 200px; margin: 0 auto; overflow: hidden;
	border: 5px solid #fff; border-radius: 50%;
	box-shadow: 0 0 5px rgba(#000, .2);

	.specialist--horizontal & {
		@include media('small') {
			margin: 0;
		}
	}
}

.specialist__graphic {
	transition: transform 500ms;
	transform: scale(1);
	display: block; width: 100%; height: 0; padding-top: 100%;
	background: color('neutrals-2') url('') no-repeat center / cover;

	.specialist:hover & {
		transform: scale(1.1);
	}
}

.specialist__content {
	flex: 0 1 auto; margin-top: gap('xsmall');
	text-align: center;

	.specialist--horizontal & {
		@include media('small') {
			margin-left: gap('small');
			text-align: left;
		}
	}
}

.specialist__title {
	transition: color 200ms;

	.specialist:hover & {
		color: color('hover');
	}
}

.specialist__desc {
	margin-top: gap('xxsmall');
}