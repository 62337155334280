/**
 *
 * BOXING: VARS
 *
 */

$breakpoints: (
	'small' 	:  500px,
	'medium' 	:  768px,
	'large' 	: 1024px,
	'xlarge' 	: 1140px,
	'xxlarge' : 1440px
);

$sections: (
	'none'		:	100%,
	'xxxsmall'	:  200px,
	'xxsmall'	:  400px,
	'xsmall'	:  600px,
	'small'		:  768px,
	'medium'	:  960px,
	'large'		: 1024px,
	'xlarge'	: 1140px,
	'xxlarge'	: 1440px,
	'xxxlarge'	: 1600px
);

$gaps: (
	'none'		:	0px,
	'xxxsmall'	:   4px,
	'xxsmall'	:   8px,
	'xsmall'	:  16px,
	'small'		:  32px,
	'medium'	:  48px,
	'large'		:  64px,
	'xlarge'	:  92px,
	'xxlarge'	: 128px,
	'xxxlarge'	: 184px
);