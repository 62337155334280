/**
 *
 * HEADINGS
 *
 */

.heading {
	display: block; width: 100%; margin: 0; padding: 0;
	font-family: font('special'); font-weight: 400;
}

.heading--0 {
	font-size: titleSize('h0') / 1.7;

	@include media('medium') {
		font-size: titleSize('h0') / 1.35;
	}

	@include media('xlarge') {
		font-size: titleSize('h0');
	}
}

.heading--1 {
	font-size: titleSize('h1') / 1.3;

	@include media('medium') {
		font-size: titleSize('h1') / 1.15;
	}

	@include media('xlarge') {
		font-size: titleSize('h1');
	}
}

.heading--2 {
	font-size: titleSize('h2') / 1.3;

	@include media('medium') {
		font-size: titleSize('h2') / 1.15;
	}

	@include media('xlarge') {
		font-size: titleSize('h2');
	}
}

.heading--3 {
	font-size: titleSize('h3') / 1.2;

	@include media('medium') {
		font-size: titleSize('h3') / 1.1;
	}

	@include media('xlarge') {
		font-size: titleSize('h3');
	}
}

.heading--4 {
	font-size: titleSize('h4') / 1.2;

	@include media('medium') {
		font-size: titleSize('h4') / 1.1;
	}

	@include media('xlarge') {
		font-size: titleSize('h4');
	}
}

.heading--5 {
	font-size: titleSize('h5') / 1.2;

	@include media('medium') {
		font-size: titleSize('h5') / 1.1;
	}

	@include media('xlarge') {
		font-size: titleSize('h5');
	}
}

.heading--6 {
	font-size: titleSize('h6') / 1.2;

	@include media('medium') {
		font-size: titleSize('h6') / 1.1;
	}

	@include media('xlarge') {
		font-size: titleSize('h6');
	}
}