/**
 *
 * TYPOGRAPHY: GENERAL
 *
 */

#{$theBody} {
	-webkit-text-size-adjust: 100%; -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); text-rendering: optimizeLegibility;
}

#{$theBucket} {
	font-family: font('main'); font-size: fontSize('small'); font-weight: normal; line-height: 1.25;

	@include media('medium') {
		font-size: fontSize('medium'); line-height: 1.375;
	}
}

.screenReader {
	left: -90210px;
	position: absolute;
}