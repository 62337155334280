/**
 *
 * ABOUT
 *
 */

.about {
	display: block; width: 100%;

	@include media('medium') {
		padding: gap('small');
	}

	@include media('medium') {
		padding: gap('medium') gap('small');
	}

	@include media('xlarge') {
		padding: gap('large') gap('small');
	}
}

.about__inner {
	display: flex; flex-wrap: wrap;

	@include media('medium') {
		display: flex; width: 100%; max-width: section('xlarge'); margin: 0 auto;
	}
}

.about__content {
	order: 2;
	flex: 1 1 100%; padding: gap('small') gap('xsmall');

	@include media('medium') {
		order: initial;
		flex: 0 0 60%; padding: 0 gap('medium') 0 0;
	}

	@include media('large') {
		padding: 0 gap('large') 0 0;
	}
}

.about__visual {
	display: block; flex: 1 1 100%; width: 100%;

	@include media('medium') {
		flex: 0 0 40%;
	}
}

.about__image {
	position: relative;
	display: block; width: 100%; height: 0; padding-top: 50%;
	background: #fff url('') no-repeat center / cover;

	@include media('medium') {
		padding-top: (4 / 6) * 100%;
		border-radius: 7px;
		overflow: hidden;
	}
}

.about__image:after {
	position: absolute; top: 0; left: 0;
	display: block; width: 100%; height: 100%;
	content: '';
	background-color: rgba(#000, .07);
}

.about__desc {
	margin-top: gap('xsmall');
}

.about__cta {
	margin-top: gap('small');
}