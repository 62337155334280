/**
 *
 * LINKS
 *
 */

a {
	h1 &,
	h2 &,
	h3 &,
	h4 &,
	h5 &,
	h6 & {
		color: inherit; text-decoration: none;
	}
}

.link {
	transition: color 200ms;
	color: color('action'); text-decoration: none;

	&:hover {
		color: color('hover');
	}
}

.link--inherit {
	color: inherit;
}