/**
 *
 * FOOTER
 *
 */

.footer {
	display: block; width: 100%; padding: gap('xsmall');
	color: #fff;
	background-color: color('brand');

	@include media('medium') {
		padding: gap('small');
	}
}

.footer__inner {
	@include media('medium') {
		display: flex; align-items: center; justify-content: space-between;
	}

	@include media('large') {
		max-width: section('xlarge'); margin: 0 auto;
	}
}

.footer__item {
	display: block; flex: 0 1 auto; margin-top: gap('small');

	@include media('medium') {
		margin-top: 0;
	}

	&:first-child {
		margin-top: 0;
	}
}

.footer__item a {
	color: inherit; text-decoration: none; font-weight: bold;
}