/**
 *
 * MAIN NAV
 *
 */

.mainNav {
	position: relative;
	display: block; width: 100%; height: 100%; padding: gap('xsmall');

	@include media('large') {
		padding: 0;
	}
}

.mainNav__list {
	position: relative;
	display: block; width: 100%; height: 100%; margin: 0; padding: 0;
	list-style-type: none;

	@include media('large') {
		display: flex; align-items: center;
	}
}

.mainNav__item {
	display: flex; align-items: center; justify-content: flex-end; padding-top: gap('xsmall');

	@include media('large') {
		position: relative;
		justify-content: flex-start; height: 100%; margin-right: gap('small'); padding-top: 0;
	}

	&:last-child {
		margin-right: 0; padding-top: 0;
	}
}

.mainNav__link {
	display: block; flex: 0 1 auto; height: 40px;
	color: #fff; font-size: fontSize('large'); text-decoration: none;

	@include media('large') {
		flex: 1 1 auto; height: auto;
		color: inherit;
	}

	&:after {
		@include media('large') {
			transition: height 200ms;
			position: absolute; bottom: 0; left: 0;
			display: block; width: 100%; height: 0;
			content: '';
		}
	}
}

.mainNav__link:hover {
	&:after {
		@include media('large') {
			height: 4px;
			background-color: color('hover');
		}
	}
}

.mainNav__link.is-active {
	font-weight: bold;

	@include media('large') {
		font-weight: normal;
	}

	&:after {
		@include media('large') {
			height: 4px;
			background-color: color('action');
		}
	}
}

.mainNav__cta {
	position: absolute; bottom: 0; right: 0;

	@include media('large') {
		position: static;
	}
}