/**
 *
 * TREATMENTS
 *
 */

.treatments {
	display: block; padding: gap('small') gap('xxsmall'); overflow: hidden;

	@include media('medium') {
		padding: gap('medium') gap('xsmall');
	}
}

.treatments__inner {
	@include media('large') {
		display: block; width: 100%; max-width: section('xlarge'); margin: 0 auto;
	}
}

.treatments__title {
	margin-bottom: gap('xsmall');
	text-align: center;
}

.treatments__content {
	display: flex; flex-wrap: wrap; margin-left: -(gap('xxsmall')); margin-right: -(gap('xxsmall'));

	@include media('large') {
		margin-top: gap('small');
	}
}

.treatments__item {
	display: flex; flex: 0 0 100%; padding: gap('xxsmall');

	@include media('small') {
		flex-basis: 50%;
	}
}