/**
 *
 * TREATMENT TEASER
 *
 */

.treatmentTeaser {
	position: relative;
	display: block; flex: 1 1 auto; padding: gap('xsmall'); overflow: hidden;
	color: inherit; text-decoration: none;
	border: 1px solid color('neutrals-1'); border-radius: 5px;
	background-color: #fff;
}

.treatmentTeaser__desc {
	margin: gap('xxsmall') 0 gap('medium');
}

.treatmentTeaser__cta {
	position: absolute; bottom: gap('xsmall'); left: gap('xsmall');
}