/**
 *
 * TYPOGRAPHY: VARS
 *
 */

$fonts: (
	'main': (
		'normal'  : 'Open Sans'
	),
	'special': (
		'normal'  : 'Asap Condensed'
	)
);

$fontSizes: (
	'inherit' : inherit,
	'xxsmall' : 10px,
	'xsmall'  : 12px,
	'small'   : 15px,
	'medium'  : 16px,
	'large'   : 18px,
	'xlarge'  : 20px,
	'xxlarge' : 22px,
);

$titleSizes: (
	'inherit' : inherit,
	'h6'  	  : 16px,
	'h5'      : 20px,
	'h4'      : 24px,
	'h3'      : 32px,
	'h2'      : 40px,
	'h1'      : 48px,
	'h0'      : 60px
);