/**
 *
 * CONTACT
 *
 */

.contact__content {
	margin-bottom: gap('xsmall');

	@include media('medium') {
		margin-bottom: gap('medium');
	}
}