/**
 *
 * HEADER
 *
 */

$headerHeight--small: 50px;
$headerHeight--medium: 70px;
$headerHeight--large: 90px;

.header {
	transition: height 200ms;
	position: relative; z-index: 9;
	display: block; width: 100%; height: $headerHeight--small;

	@include media('medium') {
		height: $headerHeight--medium;
	}

	@include media('large') {
		height: $headerHeight--large;
	}

	.page-is-scrolled & {
		@include media('large') {
			height: $headerHeight--medium;
		}
	}
}

.header__inner {
	transition: height 200ms;
	position: fixed; top: 0; left: 0;
	display: block; width: 100%; height: $headerHeight--small;

	@include media('medium') {
		height: $headerHeight--medium;
	}

	@include media('large') {
		height: $headerHeight--large;
	}

	.page-is-scrolled & {
		@include media('large') {
			height: $headerHeight--medium;
		}
	}
}

.header__content {
	transition: height 200ms;
	display: flex; align-items: center; justify-content: space-between; height: $headerHeight--small; padding: 0 gap('xxsmall');
	border-bottom: 1px solid color('neutrals-1');
	background-color: #fff;

	@include media('medium') {
		height: $headerHeight--medium; padding: 0 gap('xsmall');
	}

	@include media('large') {
		height: $headerHeight--large; max-width: section('xxxlarge'); margin: 0 auto; padding: 0 gap('small');
	}

	.page-is-scrolled & {
		@include media('large') {
			height: $headerHeight--medium;
		}
	}
}

.header__logo {
	transition: max-width 200ms;
	display: flex; align-items: center; justify-content: center; width: 100%; max-width: 120px;

	@include media('medium') {
		max-width: 150px;
	}

	@include media('large') {
		max-width: 200px;
	}

	.page-is-scrolled & {
		@include media('large') {
			max-width: 150px;
		}
	}
}

.header__nav {
	transition: margin-left 300ms;
	position: fixed; top: 0; left: 100%; z-index: 1;
	display: block; width: 320px; height: 100%;

	@include media('large') {
		position: static;
		width: auto;
	}

	.offcanvas-is-visible & {
		margin-left: -320px;
	}
}

.header__nav__overlay {
	transition: background-color 500ms, left 0s 500ms;
	position: fixed; top: 0; left: 100%; z-index: 1;
	display: block; width: 100%; height: 100%;
	background-color: rgba(color('neutrals-8'), 0);

	@include media('large') {
		display: none;
	}

	.offcanvas-is-visible & {
		transition: background-color 500ms, left 0s;
		left: 0;
		background-color: rgba(color('neutrals-8'), .3);
	}
}

.header__nav__inner {
	position: relative; z-index: 2;
	display: block; width: 320px; height: 100%; padding-top: $headerHeight--small;
	border-left: 3px solid rgba(#000, .2);
	background-color: color('brand');

	@include media('medium') {
		padding-top: $headerHeight--medium;
	}

	@include media('large') {
		width: auto; padding-top: 0;
		border-left: none;
		background-color: transparent;
	}
}

.header__nav__trigger {
	transition: color 300ms;
	position: fixed; top: 0; right: 0; z-index: 3;
	display: flex; align-items: center; justify-content: center; width: $headerHeight--small; height: $headerHeight--small;
	color: color('neutrals-6');

	@include media('medium') {
		width: $headerHeight--medium; height: $headerHeight--medium;
	}

	@include media('large') {
		display: none;
	}

	.offcanvas-is-visible & {
		color: #fff;
	}
}