/**
 *
 * CONTACT INFO
 *
 */

.contactInfo__map {
	position: relative;
	display: block; width: 100%; height: 0; padding-top: 80%;

	@include media('small') {
		padding-top: 50%;
	}

	@include media('large') {
		padding-top: 30%;
	}
}

.contactInfo__iframe {
	position: absolute; top: 0; left: 0;
	display: block; width: 100%; height: 100%;
}