/**
 *
 * BOXING: GENERAL
 *
 */

#{$theBody} {
	box-sizing: border-box;
	margin: 0;
	padding: 0;

	*, *:before, *:after {
		box-sizing: inherit;
	}

	&:before {
		content: "xsmall";
		left: -90210px;
		position: absolute;

		@include media('small') {
			content: "small";
		}

		@include media('medium') {
			content: "medium";
		}

		@include media('large') {
			content: "large";
		}

		@include media('xlarge') {
			content: "xlarge";
		}
	}
}

#{$theBucket} {
	display: block; width: 100%; max-width: section('xxxlarge'); margin: 0 auto;
	box-shadow: 0 0 100px rgba(#000,0.2);
}

#{$theBody} {
	p {
		margin: gap('xxsmall') 0 0; padding: 0;

		&:first-child {
			margin-top: 0;
		}
	}
}