/**
 *
 * TREATMENT DETAIL
 *
 */

.treatmentDetail {
	display: block; padding: gap('xxsmall');

	@include media('medium') {
		padding: gap('xsmall');
	}

	@include media('large') {
		padding: gap('small');
	}
}

.treatmentDetail__inner {
	@include media('large') {
		max-width: section('xlarge'); margin: 0 auto;
	}
}

.treatmentDetail__main {
	display: block; padding: gap('xsmall') gap('small') gap('small') gap('xsmall');
	border: 1px solid color('neutrals-1'); border-radius: 5px;
	background-color: #fff;

	@include media('large') {
		padding: gap('small') gap('small') gap('medium');
	}
}

.treatmentDetail__mainContent {
	margin-top: gap('small');
}