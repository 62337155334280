/**
 *
 * RICHTEXTCONTENT
 *
 */

.richTextContent {
	color: inherit;

	& > :first-child {
		margin-top: 0 !important;
	}

	& > :last-child {
		margin-bottom: 0 !important;
	}

	h1 {
		@extend .heading !optional;
		@extend .heading--1 !optional;
		margin-top: gap('small') !important;
	}

	h2 {
		@extend .heading !optional;
		@extend .heading--2 !optional;
		margin-top: gap('small') !important;
	}

	h3 {
		@extend .heading !optional;
		@extend .heading--3 !optional;
		margin-top: gap('small') !important;
	}

	h4 {
		@extend .heading !optional;
		@extend .heading--4 !optional;
		margin-top: gap('xsmall') !important;
	}

	h5 {
		@extend .heading !optional;
		@extend .heading--5 !optional;
		margin-top: gap('xsmall') !important;
	}

	h6 {
		@extend .heading !optional;
		@extend .heading--6 !optional;
		margin-top: gap('xsmall') !important;
	}

	p {
		margin-top: gap('xxsmall') !important;

		@include media('large') {
			margin-top: gap('xsmall') !important;
		}
	}

	strong,
	b {
		font-weight: bold;
	}

	em,
	i {
		font-style: italic;
	}

	a {
		@extend .link !optional;
	}

	hr {
		border: none;
		background-color: transparent;
		display: block;
		width: 100%;
		clear: both;
	}

	blockquote {
		display: flex;
		font-family: font('special');
		font-size: titleSize('h3');
		font-weight: normal;
		margin: 0;
		padding: gap('medium') gap('small') gap('small');

		@include media('large') {
			padding-right: gap('xlarge');
			font-size: titleSize('xlarge');
		}

		&:before {
			color: color('action');
			content: "”";
			display: block;
			font-size: 220%;
			margin-right: gap('xsmall');
			position: relative;
			top: -20px;
		}
	}

	ul {
		@extend .list !optional;

		li {
			@extend .list__item !optional;
		}
	}

	ul,
	ol {
		margin: 0;
		padding: gap('xsmall') gap('xsmall') 0 gap('small');

		@include media('large') {
			padding: gap('xsmall') gap('small') 0 gap('medium');
		}

		li {
			margin-top: gap('xxsmall') !important;

			&:first-child {
				margin-top: 0 !important;
			}
		}
	}

	figure {
		$imgBleed: section('xlarge') - section('medium');

		display: block;
		height: auto;
		margin: gap('small') 0;
		transform: translateX(-($imgBleed / 2));
		width: calc(100% + #{$imgBleed});

		@include media('large') {
			margin: gap('medium') 0;
		}
	}

	img {
		@include media('small', 'max-width') {
			display: block;
			float: none !important;
			margin-left: auto !important; margin-right: auto !important;
		}

		&.alignright {
			float: right; margin: gap('xsmall') 0 gap('xsmall') gap('xsmall');
		}

		&.alignleft {
			float: left; margin: gap('xsmall') gap('xsmall') gap('xsmall') 0;
		}
	}

	figcaption {
		display: block;
		margin: gap('xsmall') auto 0;
		max-width: section('xsmall');
		width: 100%;
		text-align: center;
		font-size: fontSize('xsmall');
	}

	iframe {
		margin: gap('small') 0;
	}
}