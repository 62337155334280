/**
 *
 * SOCIAL NAV
 *
 */

.socialNav {}

.socialNav__list {
	display: flex; align-items: center; justify-content: flex-end; margin: 0; padding: 0;
	list-style: none;
}

.socialNav__item {
	flex: 0 0 30px; margin-left: gap('xsmall');

	&:first-child {
		margin-left: 0;
	}
}

.socialNav__link {
	color: inherit;
}