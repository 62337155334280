/**
 *
 * AVATAR
 *
 */

.avatar {
	display: block; overflow: hidden;
	border: 5px solid #fff; border-radius: 50%;
	box-shadow: 0 0 5px rgba(#000, .2);
}

.avatar__graphic {
	display: block; width: 100%; height: 0; padding-top: 100%;
	background: color('neutrals-2') url('') no-repeat center / cover;
}