/**
 *
 * INLINES
 *
 */

@each $sectionName, $sectionValue, $section in $sections {

	.section--#{$sectionName} { max-width: section(#{$sectionName}); margin: 0 auto; }

}

@each $gapName, $gapValue, $gap in $gaps {

	.gap--#{$gapName} { padding-left: gap(#{$gapName}); padding-right: gap(#{$gapName}); }

}