/**
 *
 * SPECIALISTS
 *
 */

.specialists {
	display: block; padding: 0 gap('xxsmall') gap('small'); overflow: hidden;

	@include media('medium') {
		padding: 0 gap('xsmall') gap('medium');
	}
}

.specialists__inner {
	@include media('large') {
		display: block; width: 100%; max-width: section('xlarge'); margin: 0 auto;
	}
}

.specialists__title {
	margin-bottom: gap('xsmall');
	text-align: center;
}

.specialists__content {
	display: flex; flex-wrap: wrap; margin-left: -(gap('xxsmall')); margin-right: -(gap('xxsmall'));

	@include media('large') {
		margin-top: gap('small');
	}
}

.specialists__item {
	display: flex; flex: 0 0 100%; padding: gap('xxsmall');

	@include media('small') {
		flex-basis: 50%;
	}

	@include media('medium') {
		flex-basis: (100% / 3);
	}

	@include media('xlarge') {
		flex-basis: (100% / 4);
	}
}