/**
 *
 * BUTTONS
 *
 */

button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
	margin: 0; padding: 0;
	border: 0;
}

.button	{
	align-items: center;
	background-color: color('action');
	border-radius: 5px; outline: none;
	border: 2px solid color('action');
	color: color('light');
	cursor: pointer;
	display: inline-flex;
	font-family: font('main');
	font-size: inherit;
	font-weight: 400;
	height: 46px;
	justify-content: center;
	line-height: 1;
	margin: 0;
	padding: 0 gap('xsmall');
	text-decoration: none;
	text-shadow: none;
	transition: color 150ms, border-color 150ms, background-color 150ms;

	@include media('small') {
		// height: 50px;
	}

	// HOVER
	&:hover {
		border-color: color('hover');
		background-color: color('hover');
	}

	// ACTIVE
	&:active {
		transform: translateY(1px);
	}

	// DISABLED
	&[disabled]:not([disabled="false"]),
	&.disabled,
	.disabled & {
		background-color: color('disabled');
		border-color: color('disabled');
		box-shadow: none;
		color: color('light');
		cursor: not-allowed;

		&:active {
			transform: translateY(0);
		}
	}

	// CHILDREN
	& > * {
		margin-left: gap('xsmall');

		&:first-child {
			margin-left: 0;
		}
	}
}

.button--ghost {
	background-color: transparent;
	border-color: color('action');
	box-shadow: none;
	color: color('action');

	// HOVER
	&:hover {
		color: color('hover');
		border-color: color('hover');
		background-color: transparent;
	}
}

.button--small {
	font-size: 95%;
	height: 40px;
}

.button--fluid {
	display: flex; width: 100%;
}

.button--shaded {
	box-shadow: 0 3px 7px rgba(#000, .7);

	// ACTIVE
	&:active {
		box-shadow: 0 0 1px rgba(#000, .7);
	}
}

.button__label {
	flex: 0 1 auto;
}

.button__icon {
	align-items: center;
	display: flex;
	flex: 0 0 auto;
	height: fontSize('small');
	justify-content: center;
	width: fontSize('small');
}